.App {
  text-align: center;
}

#nav-container {
  justify-content: center;
}

.nav-link {
  padding: 0 10px;
}

.hero {
  padding: 10px;
  max-width: 80%;
  margin: 0 auto;
}

.content {
  margin-top: 20px;
  width: 80%;
  padding-bottom: 10px;
}
.loc-card {
  margin: 15px auto;
}
.card-btn {
  margin: 10px;
}
#embed {
  display: block;
  margin: 0 auto;
  width: 100%;
  border: none;
  height: 600px;
}

@media screen and (min-width: 480px) {
  .content {
    width: 50%;
  }
  #home-container {
    height: 250px;
  }
}
